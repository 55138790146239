body {
  margin: 0;
  padding: 0;
}

/* Style général pour mainContainer */
.main-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.shop-page {
  width: 100%;
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: "flex-start";
  align-items: "center";
  }

.shop-page-header {
  background-color: var(--store-background); /* Utilisation de variables CSS */
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.shop-page-header-deep {
  color: var(--store-text);
  justify-content: center;
}

.title-shop {
  text-align: center;
  font-family: 'NeueMachina', sans-serif;
  font-weight: 800;
}

.address-shop {
  text-align: center;
  color: var(--store-text);
  font-family: 'NeueMachina', sans-serif;
  font-size: 12px;
}

.shop-logo {
  width: 80%;
  margin-top: 30px;
  margin-bottom: 10px;
}

.logo-div {
  text-align: center;
}

.shop-info {
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 100%;
  height: auto;
  margin-bottom: 11px;
}

.shop-info-text {
  margin: 15px;
  font-size: 14px;
}

.invite-shop {
  text-align: center;
  font-family: 'NeueMachina', sans-serif;
  line-height: 25px;
  margin: 15px;
}

.action-button {
  padding: 10px 20px;
  border: solid 1px;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'NeueMachina', sans-serif;
  font-weight: 800;
  height: 32px;
  width: auto;
  margin-top: 5px;
  margin-bottom: 15px;
  text-align: center;

  /* Transition douce */
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
}

.action-button.light-bg {
  /* Si le background parent est clair */
  filter: invert(1) hue-rotate(180deg);
  background-image: linear-gradient(180deg, var(--background-button-darken), 95%, var(--background-color-darken));
  color: #fff;
  border-color: var(--background-border-darken);
  box-shadow: 0px 2px 3px var(--store-background), 
              inset 0 2px 2px var(--store-background);
}

.action-button.light-bg:hover {
  /* Si le background parent est clair */
  transform: translateY(-2px);
  
  /* Ombre plus marquée sur hover */
  box-shadow: 0px 3px 6px var(--store-background), 
              inset 0 2px 2px var(--store-background);
}

.action-button.dark-bg {
  /* Si le background parent est clair */
  filter: invert(1) hue-rotate(180deg);
  background-image: linear-gradient(180deg, var(--background-button-darken), 95%, var(--background-color-darken));
  color: #000;
  border-color: var(--background-border-darken);
  box-shadow: 0px 2px 3px var(--store-background), 
              inset 0 2px 2px var(--store-background);
}

.action-button.dark-bg:hover {
  /* Si le background parent est clair */
  transform: translateY(-2px);
  
  /* Ombre plus marquée sur hover */
  box-shadow: 0px 3px 6px var(--store-background), 
              inset 0 2px 2px var(--store-background);
}

.action-button:hover {
  transform: translateY(-5px);
  
  /* Ombre plus marquée sur hover */
  box-shadow: 0px 6px 12px rgba(10, 10, 10, 0.4), 
              inset 0 2px 4px rgba(255, 255, 255, 0.1);
  
  /* Effet de halo lumineux */
  background-image: linear-gradient(145deg, rgba(90, 90, 90, 0.4), rgba(50, 50, 50, 0.4));
}

.action-button:active {
  transform: translateY(2px); /* Enfoncement du bouton au clic */
  box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.6); /* Ombre interne plus prononcée */
}


.shop-media {
  background-color: white;
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.shop-alert {
  background-color: var(--ticket-primary);
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.phone-button {
  background-color: black;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  margin: 10px;
  width: 40px;
  height: 20px;
  text-align: center;
}

.otre-button {
  background-color: white;
  color: black;
  padding: 10px 10px;
  border-radius: 25px;
  cursor: pointer;
  margin-top: 10px;
  margin-right: 5px;
  width: 35px;
  height: 18px;
  text-align: center;
  border: 1px solid black;
}

.quote-text {
  font-family: 'Georgia', serif;
  font-size: 14px;
  line-height: 1.5;
  color: #333;
  font-style: italic;
  margin: 20px 10px;
  padding: 10px 20px;
  background-color: #f9f9f9;
  border-radius: 25px;
}

.logo-quipo {
  display: flex;
  width: 50%;
}

.logo-quipo.light-bg {
  filter: invert(0%);
}

.logo-quipo.dark-bg {
  filter: invert(100%);
}

.logo-quipo-frame {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}

.copyright {
  font-size: 8px;
  margin-top: auto;
  padding: 10px 0;
  text-align: center;
  color: #333;
}

/* Media queries */
@media only screen and (max-width: 480px) {

}

/* Pour les écrans entre 481px et 900px */
@media only screen and (min-width: 481px){
  .shop-page {
    max-width: 350px;
    min-height: 92.8vh;
    border-radius: 48px;
    display: flex;
    justify-content: "center";
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .shop-page-header {
    max-width: 330px;
    border-top-left-radius: 41px;
    border-top-right-radius: 41px;
    display: flex;
    justify-content: "center";
    align-items: center;
    margin-top: 10px;

  }

  .shop-info {
    max-width: 330px;
    border-bottom-left-radius: 41px;
    border-bottom-right-radius: 41px;
  }

  .shop-media {
    max-width: 330px;
  }

  .shop-alert {
    max-width: 330px;
  }
  
}

/* Séparateurs */
.separator-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0px 0;
}

.separator {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.separator-line {
  border-top: 2px dashed black;
  width: 100%;
  position: relative;
  z-index: 1;
}

.separator-point {
  width: 20px;
  height: 20px;
  background-color: black;
  border-radius: 50%;
  position: absolute;
  z-index: 2;
}

.left-point {
  left: 10px;
  transform: translateX(-50%);
}

.right-point {
  right: 10px;
  transform: translateX(50%);
}

@media (max-width: 480px) {
  /* Further adjustments for very small screens like phones */
  .separator-point {
    width: 16px;
    height: 16px;
  }

  .left-point {
    left: 3px;
    transform: translateX(-10px);
  }

  .right-point {
    right: 3px;
    transform: translateX(10px);
  }

  .separator-line {
    border-top-width: 1px;
  }
}