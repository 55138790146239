@font-face {
  font-family: 'NeueMachina';
  src: url('./assets/fonts/NeueMachina-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NeueMachina';
  src: url('./assets/fonts/NeueMachina-Ultrabold.otf') format('opentype');
  font-weight: 800;
  font-style: normal;
}